<template>
  <div class="box box--blog">
    <div class="box__inner box__inner--blog">
      <div class="blog-search">
        <BlogSubcategories />
        <button class="blog-search__button" type="button" @click="$router.push('/theflyreport/search')">
          <div v-if="$store.state.view.theme === 'dark'">
            <SvgIcon name="magnifier-light"/>
          </div>
          <div v-if="$store.state.view.theme === 'light'">
           <SvgIcon name="magnifier-dark"/>
          </div>
        </button>
      </div>
      <div class="blog-interface">
        <BlogArticle :prevRoute="prevRoute"/>
        <BlogFeed/>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import BlogArticle from '@/components/Blog/BlogArticle/BlogArticle';
  import BlogFeed from '@/components/Blog/BlogFeed/BlogFeed';
  import BlogSubcategories from '@/components/Blog/BlogSubcategories/BlogSubcategories';
  import './Blog.styl';

  export default {
    name: 'Article',
    data () {
      return {
        prevRoute: null,
      }
    },
    components: {
      SvgIcon,
      BlogArticle,
      BlogFeed,
      BlogSubcategories,
    },
    mounted () {
      this.$store.dispatch('getArticle', this.$route.params.id);
      this.$store.dispatch('getFeed');
    },
    unmounted () {
      this.$store.dispatch('removeArticle');
    },
    metaInfo () {
      const { article = {} } = this.$store.state.blog;
      const { attributes: { title } = {} } = article

      return {
        title,
      }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
  };
</script>
