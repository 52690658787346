<template>
  <div class="blog-article">
    <div class="blog-article__container">
      <vue-scroll :ops="ops">
          <div class="blog-article__wrapper js-blog-scrollbar">
            <div class="blog-article__date" @click="backButtonClick">
              <SvgIcon name="up-arrow" />
              <span>{{article.attributes.publishedAt | formatBlogDate }}</span>
            </div>
            <div class="blog-article__title">{{article.attributes.title}}</div>
            <div class="blog-article__author" v-if="author">
              <div>By {{author}}</div>
              <div class="blog-article__author-position">{{ authorPosition }}</div>
            </div>
            <div class="blog-article__subtitle">
              {{ article.attributes.subheading }}
            </div>
            <div class="blog-article__text" v-html="content"/>
            <div class="blog-article__footer">
              <!-- <div class="blog-article__tags">
                <div class="blog-article__tag" v-if="!dMobile">Tags:</div>
                <div v-for="tag of article.attributes.tags.data" :key="tag.id">
                  <div class="blog-article__tag">{{tag.attributes.name}}</div>
                </div>
              </div> -->
              <div class="blog-article__socials">
                <div class="blog-article__socials-list">
                  <a class="blog-article__socials-item" :href="shareUrls.facebook" target="_blank">
                    <SvgIcon name="fb2"/>
                  </a>
                  <a class="blog-article__socials-item" :href="shareUrls.twitter" target="_blank">
                    <SvgIcon name="tw"/>
                  </a>
                  <a class="blog-article__socials-item" :href="shareUrls.linkedIn" target="_blank">
                    <SvgIcon name="linkedin"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import * as MarkdownIt from 'markdown-it'
  import './BlogArticle.styl';

  export default {
    name: 'BlogArticle',
    props: ['prevRoute'],
    data: () => ({
      ops: {
        rail: {
          size: '4px',
        },
        bar: {
          size: '4px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      dMobile: undefined,
    }),
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
    },
    computed: {
      article () {
        return this.$store.state.blog.article;
      },
      author () {
        return this.article.attributes.author;
      },
      authorPosition () {
        return this.article.attributes.author_position;
      },
      cover () {
        return this.article.attributes.cover.data.attributes.url;
      },
      content () {
        return MarkdownIt({ html: true }).render(this.article.attributes.content)
      },
      shareUrls () {
        return {
          facebook: `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          twitter: `https://twitter.com/intent/tweet?url=${window.location.href}&text=${this.article.attributes.title} via @FLYJETSFLYING`,
          linkedIn: `https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${this.article.attributes.title}`,
        }
      },
    },
    methods: {
      backButtonClick () {
        if (this.prevRoute.fullPath === '/') {
          this.$router.push('/theflyreport');
        } else {
          this.$router.go(-1);
        }
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
